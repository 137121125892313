import axios from 'axios'
import config from '@config/index'
import { history } from '@app/store'
import {
  getJsonCookies,
  clearCookies,
  isFromAdmin,
  isMultiLocation,
  getAdminCookie,
  isCognitoUser,
  cognitoAccessToken,
  getThumbtackToken,
  getCookieByKey
} from '@helpers/utility'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

const createAxiosWithSentryInterceptor = (opts) => {
  const axiosInstance = axios.create(opts)
  axiosInstance.interceptors.response.use(
    (res) => res,
    (e) => {
      const url = _.get(e, 'config.url', 'N/A')
      const method = _.get(e, 'config.method', 'N/A')
      const responseStatusCode = _.get(e, 'response.status', undefined)

      // remove ignore sentry report after token management refactor is done
      if (responseStatusCode === 403) {
        console.log('ignore sentry report')
        return Promise.reject(e)
      }

      if (
        responseStatusCode === 400 &&
        _.get(e, 'config.data.error', undefined) === 'failed to authenticate Cognito token'
      ) {
        console.log('ignore sentry report')
        return Promise.reject(e)
      }
      // remove ignore sentry report after token management refactor is done

      Sentry.captureException(new Error(`[${url}] failed`), {
        extra: {
          requestHeader: _.get(e, 'config.headers', 'N/A'),
          responseHeader: _.get(e, 'response.headers', 'N/A'),
          requestBody: _.get(e, 'config.data', 'N/A'),
          responseBody: _.get(e, 'response.data', 'N/A'),
          url: url,
          method: _.get(e, 'config.method', 'N/A')
        },
        fingerprint: [url, method]
      })
      return Promise.reject(e)
    }
  )

  return axiosInstance
}

function customHeader(isAdmin, addInHeader) {
  let header = {}
  if (getThumbtackToken()) {
    header = {
      tt_token: getThumbtackToken()
    }
  }
  if (getJsonCookies()) {
    header = {
      ...header,
      'x-access-token': getJsonCookies(),
      isfromadmin: isFromAdmin()
    }
    if (isAdmin) {
      const adminCookieArr = getAdminCookie()
      if (adminCookieArr && adminCookieArr.length > 0) {
        const powertoken = adminCookieArr[0].token
        header = { ...header, powertoken: powertoken }
      }
    }
    if (addInHeader) {
      header = { ...header, ...addInHeader }
    }
    if (isMultiLocation()) {
      const host = window.location.host
      if (config.multiLocationDomain === host) {
        header = { ...header, enterprise_group: isMultiLocation() }
      }
    }
    if (isCognitoUser()) {
      header = { ...header, 'x-cognito-user': true }
    }
  } else {
    header = { isfromadmin: isFromAdmin() }
  }
  return header
}

export function api(server, addInHeader) {
  const opts = {
    baseURL: server ? config.server : config.api.trim(),
    headers: customHeader('', addInHeader)
  }
  return createAxiosWithSentryInterceptor(opts)
}

export function apiV2(server, addInHeader) {
  const opts = {
    baseURL: server ? config.serverV2 : config.apiV2.trim(),
    headers: customHeader('', addInHeader)
  }
  return createAxiosWithSentryInterceptor(opts)
}
export function apiV2Uberall(server, addInHeader) {
  const opts = {
    baseURL: server ? config.serverV2 : config.apiV2Uberall.trim(),
    headers: customHeader('', addInHeader)
  }
  return createAxiosWithSentryInterceptor(opts)
}
export function apiV2Gbm(server, addInHeader) {
  const opts = {
    baseURL: server ? config.serverV2 : config.apiV2Gbm.trim(),
    headers: customHeader('', addInHeader)
  }
  return createAxiosWithSentryInterceptor(opts)
}
export function adminApi(server) {
  const opts = {
    baseURL: server ? config.server : config.api.trim(),
    headers: customHeader(true)
  }
  return createAxiosWithSentryInterceptor(opts)
}
export function mediaApi(mediaServer) {
  const opts = {
    baseURL: mediaServer ? config.mediaServer : config.mediaApi.trim(),
    headers: customHeader()
  }
  return createAxiosWithSentryInterceptor(opts)
}
export function builderApi() {
  const opts = {
    baseURL: config.builderApi.trim(),
    headers: customHeader()
  }
  return createAxiosWithSentryInterceptor(opts)
}

export function invoiceApi() {
  const opts = {
    baseURL: config.invoiceApi.trim(),
    headers: customHeader()
  }
  return createAxiosWithSentryInterceptor(opts)
}

export function paymentApi() {
  const opts = {
    baseURL: config.paymentApi.trim(),
    headers: customHeader()
  }
  return createAxiosWithSentryInterceptor(opts)
}

export function onboardingApi() {
  const opts = {
    baseURL: config.onboardingApi.trim(),
    headers: customHeader()
  }
  return createAxiosWithSentryInterceptor(opts)
}

export function cognitoApi() {
  const opts = {
    baseURL: config.cognitoApi.trim(),
    headers: cognitoCustomHeader()
  }
  return createAxiosWithSentryInterceptor(opts)
}

export function catchHandler(e) {
  const res = JSON.parse(JSON.stringify(e)).response
  if (res && res.status === 401) {
    clearCookies()
    history.push('/login')
  }
  throw res
}

export function catchHandlerForPaymentIntent(e) {
  if (e && e === 401) {
    history.push('/login')
  }
  throw e
}

export function meApiCatchHandler(e) {
  const res = JSON.parse(JSON.stringify(e)).response
  if (res && (res.status === 401 || res.status === 403 || res.status === 400)) {
    clearCookies()
    const reviewId = getCookieByKey('reviewPageId')
    console.log('reviewId ==========', reviewId)
    if (reviewId) {
      history.push(`/login?reviewPageId=${reviewId}`)
    } else {
      history.push('/login')
    }
  }
  throw res
}

export function adminDownloadApi(server) {
  const opts = {
    baseURL: server ? config.server : config.api.trim(),
    headers: customHeader(true),
    responseType: 'blob'
  }
  return createAxiosWithSentryInterceptor(opts)
}

function cognitoCustomHeader() {
  let header = {}
  if (getThumbtackToken()) {
    header = {
      tt_token: getThumbtackToken()
    }
  }
  if (getJsonCookies()) {
    header = {
      ...header,
      'x-access-token': getJsonCookies()
    }
    // if (isCognitoUser()) {
    //   header = { ...header, 'x-cognito-user': true }
    // }
    const cogToken = cognitoAccessToken()
    if (cogToken) {
      header = { ...header, 'x-cognito-token': cogToken }
    }
  }
  return header
}
